
import router from '@/router'
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
    props: ['withExchangeButtom'],
    setup() {
        const authStore: any = inject('authState')
        const goTo = (url: any) => {
            router.push({ name: url })
        }
        return {
            userData: computed(() => authStore.state.userData),
            goTo
        }
    },
})
