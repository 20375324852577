import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0bb6991a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "exchangeBoxGroupContainer" }
const _hoisted_2 = { id: "exchangeBoxGroupContent" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ExchangeBox = _resolveComponent("ExchangeBox")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", null, _toDisplayString(_ctx.$t('Testers_redeem_index_select_option')), 1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.types, (type) => {
        return (_openBlock(), _createBlock(_component_ExchangeBox, {
          key: type,
          type: type,
          onOpen: ($event: any) => (_ctx.openRedeem(type))
        }, null, 8, ["type", "onOpen"]))
      }), 128))
    ])
  ]))
}