
import { defineComponent, inject, computed } from 'vue'
import PointsInfo from '@/components/PointsInfo.vue'
import Redeem from '@/components/Redeem.vue'
import ExchangeBoxGroup from '@/components/ExchangeBoxGroup.vue'

export default defineComponent({
    components: { Redeem, ExchangeBoxGroup, PointsInfo },
    setup() {
        const exchangeStore: any = inject('exchangeStore')
        return {
            selectedRedeemType: computed(
                () => exchangeStore.state.redeemBox.selectedRedeemType
            ),
        }
    },
})
