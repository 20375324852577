
import { computed, defineComponent, inject } from 'vue'
import ExchangeBox from '@/components/ExchangeBox.vue'

export default defineComponent({
    components: {ExchangeBox},
    setup() {
        const exchangeStore: any = inject('exchangeStore');
        const openRedeem = (type: string)=>{
            exchangeStore.setActualRedeemType(type)
            exchangeStore.setShowRedeem(true);
        }
        return{
            openRedeem,
            types: computed(()=>exchangeStore.state.redeemBox.types)
        }
    },
})
